define("ember-google-maps/components/g-map/polyline", ["exports", "ember-google-maps/components/g-map/typical-map-component"], function (_exports, _typicalMapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Polyline extends _typicalMapComponent.default {
    get name() {
      return 'polylines';
    }
    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.Polyline(options);
    }
  }
  _exports.default = Polyline;
});