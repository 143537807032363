define("ember-svg-jar/inlined/component-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.986 15.535l.006.003c.52.361 1.136.518 1.748.444a2.621 2.621 0 001.618-.846l.378-.413c.17-.185.264-.435.264-.696 0-.26-.095-.51-.264-.695l-1.594-1.738a.866.866 0 00-.638-.288.866.866 0 00-.638.288.9.9 0 01-.292.213.836.836 0 01-.69 0 .9.9 0 01-.293-.213L9.041 8.81a1.032 1.032 0 01-.265-.696c0-.26.095-.511.264-.696.084-.091.15-.2.196-.319a1.06 1.06 0 000-.752.993.993 0 00-.196-.32l-1.594-1.74A.866.866 0 006.81 4a.866.866 0 00-.638.288l-.379.413a3.067 3.067 0 00-.776 1.764c-.067.668.076 1.34.407 1.907l.004.006c1.747 2.821 3.973 5.25 6.56 7.157z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});