define("ember-svg-jar/inlined/tree-envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.3 6v-.5a.5.5 0 00-.5.5h.5zm15.4 0h.5a.5.5 0 00-.5-.5V6zm0 11v.5a.5.5 0 00.5-.5h-.5zM3.3 17h-.5a.5.5 0 00.5.5V17zM18.756 6.8a.5.5 0 10-.61-.793l.61.793zm-6.277 4.197l-.305-.396.305.396zm-1.48.503V11v.5zm-1.478-.503l.305-.396-.305.396zm-5.668-4.99a.5.5 0 10-.61.793l.61-.793zM3.3 6.5h15.4v-1H3.3v1zM18.2 6v11h1V6h-1zm.5 10.5H3.3v1h15.4v-1zM3.8 17V6h-1v11h1zM18.146 6.007l-5.972 4.594.61.792L18.756 6.8l-.61-.793zm-5.972 4.594c-.337.259-.75.399-1.174.399v1c.645 0 1.272-.213 1.784-.607l-.61-.792zM11 11c-.425 0-.838-.14-1.174-.4l-.61.793A2.926 2.926 0 0011 12v-1zm-1.174-.4L3.853 6.008l-.61.793 5.973 4.593.61-.792z\" fill=\"#6B7280\"/>",
    "attrs": {
      "viewBox": "0 0 22 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});