define("ember-svg-jar/inlined/tree-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.7 18.43v2.466a.698.698 0 00.7.704h7.7a.706.706 0 00.7-.704V6.104a.706.706 0 00-.7-.704h-7.7a.698.698 0 00-.7.704V8.57M18.2 13.5h-13\" stroke=\"#6B7280\"/><path d=\"M14.3 17.55l3.9-4.05-3.9-4.05\"/>",
    "attrs": {
      "viewBox": "0 0 26 27",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});