define("ember-svg-jar/inlined/component-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.4 3.998H3.6v6.8h12.8v-6.8zM3.6 13.2h4.8m-4.8 2.4h4.8m2.4.4v-2.8h5.6V16\"/><path d=\"M7.2 10.8l3.165-3.52a2.166 2.166 0 013.3.095L16.4 10.8m-10-3.602a.4.4 0 100-.8.4.4 0 000 .8z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});