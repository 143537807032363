define("ember-svg-jar/inlined/component-menu-children", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.077 5.538a.77.77 0 01-.77.77H3.77a.77.77 0 01-.77-.77V4.77A.77.77 0 013.77 4h11.538a.77.77 0 01.769.77v.768zM18 10.154a.77.77 0 01-.77.77H5.693a.77.77 0 01-.769-.77v-.77a.77.77 0 01.77-.769H17.23a.77.77 0 01.769.77v.769zm0 4.616a.77.77 0 01-.77.769H5.693a.77.77 0 01-.769-.77V14a.77.77 0 01.77-.77H17.23A.769.769 0 0118 14v.77z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});