define("ember-svg-jar/inlined/preview-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M24 88c0-30.931 25.069-56 56-56h140v112c0 15.487 12.513 28 28 28h112v252c0 30.888-25.112 56-56 56H80c-30.931 0-56-25.112-56-56V88zm224 56V32l112 112H248z\"/>",
    "attrs": {
      "style": "transform-origin:.375em .5em",
      "class": "svg-inline--fa fa-file fa-2x",
      "data-prefix": "fas",
      "data-icon": "file",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 384 512"
    }
  };
  _exports.default = _default;
});