define("ember-svg-jar/inlined/component-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 4H4c-.698 0-1.263.566-1.263 1.263v9.474C2.737 15.434 3.302 16 4 16h12c.698 0 1.263-.566 1.263-1.263V5.263C17.263 4.566 16.698 4 16 4zM2.737 6.526h14.526M2.737 13.474h14.526\"/><path d=\"M4.796 5.421a.158.158 0 010-.316m0 .316a.158.158 0 000-.316m2.602.316a.158.158 0 010-.316m0 .316a.158.158 0 100-.316M10 5.421a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 000-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m-10.408 9.79a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m2.602.316a.158.158 0 110-.316m0 .316a.158.158 0 100-.316m-3.309-4.156a.448.448 0 000-.846L8.737 8.105a.227.227 0 00-.202 0 .24.24 0 00-.095.183v3.348a.24.24 0 00.095.183.228.228 0 00.202.076l3.158-1.472z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});