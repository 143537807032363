define("ember-svg-jar/inlined/analytics-board-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>analytics-board-graph-line</title><rect x=\".504\" y=\"3.5\" width=\"23\" height=\"17\" rx=\"1\" ry=\"1\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M4.5 15.5l2.571-4.285a.5.5 0 01.858 0l2.213 3.685a.5.5 0 00.813.063l1.665-2a.5.5 0 01.768 0l1.655 1.986a.5.5 0 00.818-.072L19.5 8.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  };
  _exports.default = _default;
});