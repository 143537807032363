define("ember-google-maps/components/g-map/canvas", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)( /*
                                                                                <div id={{@id}} class="ember-google-map" ...attributes {{g-map/did-insert @onCanvasReady}}>{{yield}}</div>
                                                                              */{
    "id": "E8sUVeLo",
    "block": "[[[11,0],[16,1,[30,1]],[24,0,\"ember-google-map\"],[17,2],[4,[38,0],[[30,3]],null],[12],[18,4,null],[13]],[\"@id\",\"&attrs\",\"@onCanvasReady\",\"&default\"],false,[\"g-map/did-insert\",\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/canvas.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});