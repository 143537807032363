define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.793 10.154V8.292a.92.92 0 00-.279-.658.965.965 0 00-.674-.272H9.167V6.43a.92.92 0 00-.28-.658.965.965 0 00-.673-.273h-2.86a.965.965 0 00-.674.273.92.92 0 00-.28.658v10.114c.004.254.1.497.273.687a1.102 1.102 0 001.402.18c.217-.14.375-.351.446-.595l1.494-5.992a.93.93 0 01.343-.485.969.969 0 01.573-.186h8.816a.972.972 0 01.756.363.92.92 0 01.165.807L17.27 16.91a.93.93 0 01-.34.498.968.968 0 01-.581.193H5.479\" stroke=\"#6B7280\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});