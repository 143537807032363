define("ember-svg-jar/inlined/preview-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M112 60c0-15.461 12.512-28 28-28 15.487 0 28 12.539 28 28v28h112V60c0-15.461 12.513-28 28-28s28 12.539 28 28v28h42c23.188 0 42 18.804 42 42v42H28v-42c0-23.196 18.804-42 42-42h42V60zm308 378c0 23.188-18.813 42-42 42H70c-23.196 0-42-18.813-42-42V200h392v238z\"/>",
    "attrs": {
      "style": "transform-origin:.4375em .5em",
      "class": "svg-inline--fa fa-calendar fa-2x",
      "data-prefix": "fas",
      "data-icon": "calendar",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 448 512"
    }
  };
  _exports.default = _default;
});