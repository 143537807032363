define("ember-svg-jar/inlined/tree-tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.673 4.327H4.326c-.567 0-1.026.46-1.026 1.026v12.32c0 .567.46 1.027 1.026 1.027h13.347c.567 0 1.027-.46 1.027-1.027V5.353c0-.567-.46-1.026-1.027-1.026zM6.38 3.3v2.053M15.62 3.3v2.053M11.513 15.107h3.08M11.513 9.973h3.08\"/><path d=\"M9.562 13.259l-2.156 2.875-1.54-1.54M9.562 7.92l-2.156 2.875-1.54-1.54\"/>",
    "attrs": {
      "viewBox": "0 0 22 22",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});