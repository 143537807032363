define("ember-svg-jar/inlined/component-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.652 6.087a2.087 2.087 0 104.174 0 2.087 2.087 0 00-4.174 0zm2.087 2.087v6.26m1.565-.496c2.687.104 4.696.521 4.696 1.018C16 15.53 13.314 16 10 16s-6-.47-6-1.043c0-.465 1.748-.86 4.162-.992\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});