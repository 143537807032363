define("ember-svg-jar/inlined/paperclip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M346.675 105.325c-21.35-21.359-56-21.359-77.35 0l-161 161c-36.829 36.837-36.829 96.513 0 133.35 36.838 36.837 96.513 36.837 133.35 0l133-133c9.538-9.538 25.112-9.538 34.65 0 9.538 9.537 9.538 25.112 0 34.65l-133 133c-56 56-146.65 56-202.65 0-55.956-56-55.956-146.65 0-202.65l161-161c40.513-40.495 106.138-40.495 146.65 0 40.513 40.495 40.513 106.137 0 146.65l-154 154c-25.025 25.025-65.625 25.025-90.65 0-25.034-25.025-25.034-65.625 0-90.65l126-126c9.538-9.538 25.113-9.538 34.65 0 9.538 9.537 9.538 25.112 0 34.65l-126 126c-5.862 5.862-5.862 15.487 0 21.35 5.863 5.862 15.488 5.862 21.35 0l154-154c21.35-21.35 21.35-56 0-77.35z\"/>",
    "attrs": {
      "style": "transform-origin:.4375em .5em",
      "class": "svg-inline--fa fa-paperclip fa-2x",
      "data-prefix": "fas",
      "data-icon": "paperclip",
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 448 512"
    }
  };
  _exports.default = _default;
});