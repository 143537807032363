define("ember-google-maps/services/google-maps-api", ["exports", "@ember/service", "rsvp", "@ember/application", "@ember/runloop", "@ember/debug", "@ember/test-waiters", "ember-google-maps/utils/async-data"], function (_exports, _service, _rsvp, _application, _runloop, _debug, _testWaiters, _asyncData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let GoogleMapsApiService = (_class = class GoogleMapsApiService extends _service.default {
    get google() {
      return this._getApi();
    }
    get directionsService() {
      return this.google.then(google => new google.maps.DirectionsService());
    }

    /**
     * By default, this returns the Google Maps URL created at build time. You can
     * use this hook to build the URL at runtime instead.
     *
     * Optionally, you can return a promise that resolves with the URL. This
     * allows you to use external data when building the URL. For example, you
     * could fetch the database record for the current user for localisation
     * purposes.
     */
    buildGoogleMapsUrl(config) {
      return config['src'];
    }

    /**
     * Get the configuration for ember-google-maps set in environment.js. This
     * should contain your API key and any other options you set.
     */
    _getConfig() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-google-maps'];
    }

    /**
     * Return or load the Google Maps API.
     */
    _getApi() {
      var _window, _window$google;
      if (typeof document === 'undefined') {
        return (0, _rsvp.reject)();
      }
      if ((_window = window) !== null && _window !== void 0 && (_window$google = _window.google) !== null && _window$google !== void 0 && _window$google.maps) {
        return (0, _rsvp.resolve)(window.google);
      }
      let config = this._getConfig();
      return (0, _rsvp.resolve)(config).then(this.buildGoogleMapsUrl).then(this._loadAndInitApi);
    }
    _loadAndInitApi(src) {
      (true && !(src) && (0, _debug.assert)(`
ember-google-maps: You tried to load the Google Maps API, but the source URL was empty. \
Perhaps you forgot to specify the API key? \
Learn more: https://ember-google-maps.sandydoo.me/docs/getting-started`, src));
      return new _rsvp.Promise((resolve, reject) => {
        window.initGoogleMap = (0, _runloop.bind)(() => {
          resolve(window.google);
        });
        let s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.onerror = error => reject(error);
        // Insert into DOM to avoid CORS problems
        document.body.appendChild(s);

        // Load map
        s.src = `${src}&callback=initGoogleMap`;
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "google", [_asyncData.getAsync], Object.getOwnPropertyDescriptor(_class.prototype, "google"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "directionsService", [_asyncData.getAsync], Object.getOwnPropertyDescriptor(_class.prototype, "directionsService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getApi", [_testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "_getApi"), _class.prototype)), _class);
  _exports.default = GoogleMapsApiService;
});