define("ember-svg-jar/inlined/tree-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.21 18.614c0 .255-.079.5-.218.681a.673.673 0 01-.528.282H6.535a.673.673 0 01-.527-.282 1.12 1.12 0 01-.219-.681V6.094c0-.255.079-.5.219-.68.14-.18.33-.282.527-.282h7.46a.67.67 0 01.52.273l1.47 1.851c.072.09.128.197.167.315.038.119.058.246.058.375v10.668z\"/>",
    "attrs": {
      "viewBox": "0 0 22 23",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});