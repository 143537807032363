define("ember-svg-jar/inlined/component-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.933 12.8l5.134-5.6m-4.652 2.811a2.021 2.021 0 00-.903.152c-.288.12-.55.304-.768.541l-1.1 1.2A2.514 2.514 0 005 13.6c0 .315.057.627.167.918.111.291.273.556.477.778.205.223.447.4.714.52.267.12.553.183.842.183.583 0 1.143-.253 1.556-.703l1.1-1.2c.217-.237.386-.522.496-.836.11-.314.157-.65.14-.985m2.093-2.288c.308.02.615-.032.903-.152.288-.12.55-.304.768-.541l1.1-1.2c.412-.45.644-1.06.644-1.696 0-.636-.232-1.246-.644-1.696A2.111 2.111 0 0013.8 4c-.584 0-1.143.253-1.556.703l-1.1 1.2a2.418 2.418 0 00-.495.836c-.109.314-.156.65-.138.985\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});