define("ember-svg-jar/inlined/component-calendar-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.067 5H3.933A.928.928 0 003 5.923v10.154c0 .51.418.923.933.923h12.134a.928.928 0 00.933-.923V5.923A.928.928 0 0016.067 5zM3 8h14M6 6V3m7 3V3\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});