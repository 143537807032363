self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-data:deprecate-promise-proxies' },
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-non-strict-relationships',
    },
    { handler: 'silence', matchId: 'ember-data:deprecate-array-like' },
    { handler: 'silence', matchId: 'ember-data:no-a-with-array-like' },
    {
      handler: 'silence',
      matchId: 'ember-data:deprecate-promise-many-array-behaviors',
    },
  ],
};
