define("ember-svg-jar/inlined/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.421 14.614c0 .255-.079.5-.218.681a.673.673 0 01-.528.282H1.746a.673.673 0 01-.528-.282A1.12 1.12 0 011 14.614V2.094c0-.255.079-.5.218-.68.14-.18.33-.282.528-.282h7.46a.67.67 0 01.52.273l1.47 1.851c.072.09.128.197.167.315.038.119.058.246.058.375v10.668z\" stroke=\"#6B7280\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "12",
      "height": "17",
      "viewBox": "0 0 12 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});