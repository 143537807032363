define("ember-svg-jar/inlined/component-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.435 4H4.565A.565.565 0 004 4.565v11.87c0 .312.253.565.565.565h11.87a.565.565 0 00.565-.565V4.565A.565.565 0 0016.435 4zm-6.218 8.478v2.261M4 14.74h13\"/><path d=\"M11.039 9.098c.37-.36.855-.575 1.369-.607.765.004 1.552.126 1.642.608-.07.52-.87.645-1.572.615a2.035 2.035 0 01-1.44-.616zm-1.679 0a2.162 2.162 0 00-1.368-.607c-.766.004-1.553.126-1.643.608.07.52.87.645 1.572.615a2.035 2.035 0 001.44-.616zm.355-.869a2.157 2.157 0 00-.133-1.491c-.376-.668-.865-1.295-1.331-1.14-.42.315-.14 1.074.226 1.672a2.03 2.03 0 001.238.959zm.912 0a2.159 2.159 0 01.134-1.491c.375-.668.864-1.295 1.329-1.14.422.315.14 1.074-.226 1.672a2.031 2.031 0 01-1.237.959zm-.918 1.698a2.16 2.16 0 01-.134 1.492c-.376.667-.865 1.294-1.33 1.14-.421-.315-.14-1.074.226-1.673a2.034 2.034 0 011.238-.96zm.914 0a2.162 2.162 0 00.133 1.492c.376.667.865 1.294 1.33 1.14.421-.315.14-1.074-.226-1.673a2.035 2.035 0 00-1.237-.96z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});